import fetch from "../interceptor/fetchInterceptor";

const PmService = {};

PmService.GetProfile = function () {
  return fetch({
    url: "/detail/property-manager",
    method: "get",
    headers: {
      "public-request": "true",
    },
  });
};

PmService.UpdateProfile = function (data) {
  return fetch({
    url: "/update/property-manager",
    method: "post",
    headers: {
      "public-request": "true",
      "Content-Type": "multipart/form-data",
    },
    data: data,
  });
};
PmService.ChangePassword = function (data) {
  return fetch({
    url: "/auth/updatePassword",
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
};

PmService.GetPropertyList = function (status) {
  return fetch({
    url: `/property-manager/property/list/${status}`,
    method: "get",
    headers: {
      "public-request": "true",
    },
  });
};

PmService.AddProperty = function (data) {
  return fetch({
    url: "/property-manager/property/create",
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
};

PmService.updateProperty = function (data,id) {
  return fetch({
    url: `/property-manager/property/update/${id}`,
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
};


PmService.GetPropertyById = function (id) {
  return fetch({
    url: `/property-manager/property/${id}`,
    method: "get",
    headers: {
      "public-request": "true",
    },
  });
};

PmService.UpdatePropertyStatus = function (id, data) {
  return fetch({
    url: `/property-manager/property/change-status/${id}`,
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  })
}

PmService.GetMainServiceList = function () {
  return fetch({
    url: "/property-manager/services",
    method: "get",
    headers: {
      "public-request": "true",
    },
  });
};

PmService.GetSubServices = function (id, status) {
  return fetch({
    url: `/property-manager/service/sub-service/${id}/${status}`,
    method: "get",
    headers: {
      "public-request": "true",
    },
  });
};

PmService.AddSubService = function (data) {
  return fetch({
    url: "/property-manager/service/sub-service/add",
    method: "post",
    headers: {
      "public-request": "true",
      "Content-Type": "multipart/form-data",
    },
    data: data,
  });
};

PmService.UpdateSubService = function (data, id) {
  return fetch({
    url: `/property-manager/service/sub-service/update/${id}`,
    method: "post",
    headers: {
      "public-request": "true",
      "Content-Type": "multipart/form-data",
    },
    data: data,
  });
};

PmService.DeleteSubService = function (id) {
  return fetch({
    url: `/property-manager/service/sub-service/${id}`,
    method: "delete",
    headers: {
      "public-request": "true",
    },
  });
};

PmService.GetMainServicesByProperty = function (id) {
  return fetch({
    url: `/property-manager/property/services/${id}`,
    method: "get",
    headers: {
      "public-request": "true",
    },
  });
};

PmService.GetSubServicesByProperty = function (pid, sid) {
  return fetch({
    url: `/property-manager/property/services/sub-service/${pid}/${sid}`,
    method: "get",
    headers: {
      "public-request": "true",
    },
  });
};

PmService.GetAttachedSubServicesByProperty = function (pid, sid) {
  return fetch({
    url: `/property-manager/property/attached/services/${pid}/${sid}`,
    method: "get",
    headers: {
      "public-request": "true",
    },
  });
};

PmService.AttachSubServiceToProperty = function (data) {
  return fetch({
    url: `/property-manager/property/services/sub-service/attach`,
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
};

PmService.DettachSubServiceToProperty = function (data) {
  return fetch({
    url: `/property-manager/property/services/sub-service/detach`,
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
};

PmService.UpdatePmDescForSubServiceToProperty = function (data) {
  return fetch({
    url: `/property-manager/property/services/sub-service/update-pm-desc`,
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
};

PmService.GetAllServicesCategory = function (service_id, status) {
  return fetch({
    url: `/property-manager/service/categories/${service_id}/${status}`,
    method: "get",
    headers: {
      "public-request": "true",
    },
  });
};

PmService.GetOrderList = function (data) {
  return fetch({
    url: `/property-manager/order/list`,
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data
  });
};

PmService.GetOrderDetail = function (id) {
  return fetch({
    url: `/property-manager/order/detail/${id}`,
    method: "get",
    headers: {
      "public-request": "true",
    }
  });
};

PmService.GetOrderAnalytics = function (data) {
  return fetch({
    url: `/property-manager/order/analytics`,
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data
  });
};

PmService.UpdateOrderStatus = function (data) {
  return fetch({
    url: `/property-manager/order/update-status`,
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
};

PmService.getGuestInfo = function (id) {
  return fetch({
    url: `/property-manager/guest-info/list/`+id,
    method: "get",
    headers: {
      "public-request": "true",
    }
  });
};


PmService.getBookings = function () {
  return fetch({
    url: `/property-manager/booking-id/list`,
    method: "get",
    headers: {
      "public-request": "true",
    },
  });
}

PmService.getDashboard = function (data) {
  return fetch({
    url: `/property-manager/dashboard`,
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
}

PmService.getOrderStatusCountList = function (data) {
  return fetch({
    url: `/property-manager/order-status-count`,
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
}

PmService.getLastTwelveMonthsRevenue = function () {
  return fetch({
    url: `/property-manager/twelve-months-revenue`,
    method: "get",
    headers: {
      "public-request": "true",
    },
  });
}

PmService.ReorderSubServices = function (id, data) {
  return fetch({
    url: `/property-manager/service/sub-service/order-change/${id}`,
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data
  });
}

PmService.AddNotification = function (data) {
  return fetch({
    url: `/property-manager/service-notification/add`,
    method: "post",
    headers: {
      "public-request": "true",
      "Content-Type": "multipart/form-data",
    },
    data: data
  });
}

PmService.UpdateNotification = function (data, id) {
  return fetch({
    url: `/property-manager/service-notification/update/${id}`,
    method: "post",
    headers: {
      "public-request": "true",
      "Content-Type": "multipart/form-data",
    },
    data: data
  });
}

PmService.GetAllServiceNotifications = function () {
  return fetch({
    url: `/property-manager/service-notification/list`,
    method: "get",
    headers: {
      "public-request": "true",
    }
  });
}

PmService.GetServiceCategories = function () {
  return fetch({
    url: `/admin/service/categories/all/1`,
    method: "get",
    headers: {
      "public-request": "true",
    }
  });
}

export default PmService;
